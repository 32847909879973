// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\wamp64\\www\\github-repos\\appseed-subscription\\react\\superprops-landing-page\\packages\\landing-gatsby\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agency-js": () => import("C:\\wamp64\\www\\github-repos\\appseed-subscription\\react\\superprops-landing-page\\packages\\landing-gatsby\\src\\pages\\agency.js" /* webpackChunkName: "component---src-pages-agency-js" */),
  "component---src-pages-app-js": () => import("C:\\wamp64\\www\\github-repos\\appseed-subscription\\react\\superprops-landing-page\\packages\\landing-gatsby\\src\\pages\\app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-buyme-js": () => import("C:\\wamp64\\www\\github-repos\\appseed-subscription\\react\\superprops-landing-page\\packages\\landing-gatsby\\src\\pages\\buyme.js" /* webpackChunkName: "component---src-pages-buyme-js" */),
  "component---src-pages-hosting-js": () => import("C:\\wamp64\\www\\github-repos\\appseed-subscription\\react\\superprops-landing-page\\packages\\landing-gatsby\\src\\pages\\hosting.js" /* webpackChunkName: "component---src-pages-hosting-js" */),
  "component---src-pages-index-js": () => import("C:\\wamp64\\www\\github-repos\\appseed-subscription\\react\\superprops-landing-page\\packages\\landing-gatsby\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("C:\\wamp64\\www\\github-repos\\appseed-subscription\\react\\superprops-landing-page\\packages\\landing-gatsby\\src\\pages\\page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-portfolio-js": () => import("C:\\wamp64\\www\\github-repos\\appseed-subscription\\react\\superprops-landing-page\\packages\\landing-gatsby\\src\\pages\\portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-ride-js": () => import("C:\\wamp64\\www\\github-repos\\appseed-subscription\\react\\superprops-landing-page\\packages\\landing-gatsby\\src\\pages\\ride.js" /* webpackChunkName: "component---src-pages-ride-js" */),
  "component---src-pages-saas-js": () => import("C:\\wamp64\\www\\github-repos\\appseed-subscription\\react\\superprops-landing-page\\packages\\landing-gatsby\\src\\pages\\saas.js" /* webpackChunkName: "component---src-pages-saas-js" */),
  "component---src-pages-saasclassic-js": () => import("C:\\wamp64\\www\\github-repos\\appseed-subscription\\react\\superprops-landing-page\\packages\\landing-gatsby\\src\\pages\\saasclassic.js" /* webpackChunkName: "component---src-pages-saasclassic-js" */),
  "component---src-pages-saasmodern-js": () => import("C:\\wamp64\\www\\github-repos\\appseed-subscription\\react\\superprops-landing-page\\packages\\landing-gatsby\\src\\pages\\saasmodern.js" /* webpackChunkName: "component---src-pages-saasmodern-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\wamp64\\www\\github-repos\\appseed-subscription\\react\\superprops-landing-page\\packages\\landing-gatsby\\.cache\\data.json")

